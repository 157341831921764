const classifications_data = [
  {
    id: "1",
    name: "Winged or Once-Winged Insect",
    image:
      "https://www.thoughtco.com/thmb/vpZ1RgVOmXLUD1caJpFqVZzeats=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/4687789352_358aa1719c_o-56a520143df78cf772865f52.jpg",
    accuracy: 93,
    count: 39,
  },
  {
    id: "1",
    name: "Moth",
    image: "https://www.amentsoc.org/images/red-admiral1.jpg",
    accuracy: 84,
    count: 51,
  },
  {
    id: "1",
    name: "Stag Beetle",
    image:
      "https://lawrencehallofscience.org/wp-content/uploads/2023/04/Stag-Beetle-scaled.jpg",
    accuracy: 59,
    count: 19,
  },
  {
    id: "1",
    name: "Mosquito",
    image:
      "https://www.nih.gov/sites/default/files/styles/floated_media_breakpoint-medium/public/news-events/research-matters/2022/20220607-mosquito.jpg?itok=3H8-5WLz&timestamp=1654612764",
    accuracy: 79,
    count: 22,
  },
  {
    id: "1",
    name: "Ant",
    image:
      "https://i.guim.co.uk/img/media/90af49397bcc5dc07b10cb4582c61ab748cf8176/0_264_1500_900/master/1500.jpg?width=1200&height=1200&quality=85&auto=format&fit=crop&s=7eccd0da40c74fd2da8ecc7d3009e276",
    accuracy: 99,
    count: 104,
  },
  {
    id: "1",
    name: "Winged or Once-Winged Insect",
    image:
      "https://www.thoughtco.com/thmb/vpZ1RgVOmXLUD1caJpFqVZzeats=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/4687789352_358aa1719c_o-56a520143df78cf772865f52.jpg",
    accuracy: 49,
    count: 42,
  },
  {
    id: "1",
    name: "Moth",
    image: "https://www.amentsoc.org/images/red-admiral1.jpg",
    accuracy: 49,
    count: 49,
  },
  {
    id: "1",
    name: "Stag Beetle",
    image:
      "https://lawrencehallofscience.org/wp-content/uploads/2023/04/Stag-Beetle-scaled.jpg",
    accuracy: 93,
    count: 49,
  },
  {
    id: "1",
    name: "Mosquito",
    image:
      "https://www.nih.gov/sites/default/files/styles/floated_media_breakpoint-medium/public/news-events/research-matters/2022/20220607-mosquito.jpg?itok=3H8-5WLz&timestamp=1654612764",
    accuracy: 93,
    count: 49,
  },
  {
    id: "1",
    name: "Ant",
    image:
      "https://i.guim.co.uk/img/media/90af49397bcc5dc07b10cb4582c61ab748cf8176/0_264_1500_900/master/1500.jpg?width=1200&height=1200&quality=85&auto=format&fit=crop&s=7eccd0da40c74fd2da8ecc7d3009e276",
    accuracy: 93,
    count: 49,
  },
];

export default classifications_data;
