import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import SettingsComponent from "../../components/portal_components/SettingsComponents/SettingsComponent";

const SettingsPage = () => {
  return (
    <>
      <Sidebar childComponent={<SettingsComponent />} activeText="Settings" />
    </>
  );
};

export default SettingsPage;
