import React, { useState, useEffect } from "react";
import styles from "./classifications.module.css";
import classifications_data from "../../../data/classifications_data";

import SearchBarHeader from "../UniversalComponents/search_bar_header";
import ClassificationsCard from "./ClassificationCard";

function ClassificationsComponent() {
  const [classifications, setClassifications] = useState([]);
  const [sortOption, setSortOption] = useState("newest"); // Default sort option

  // Effect to sort classifications whenever sortOption or classifications_data changes
  useEffect(() => {
    sortClassifications(sortOption);
  }, [sortOption, classifications_data]);

  // Function to handle sorting
  const sortClassifications = (option) => {
    let sortedData = [...classifications_data];
    if (option === "alphabetical") {
      sortedData.sort((a, b) => a.name.localeCompare(b.name)); // Assuming `name` field exists
    } else if (option === "newest") {
      sortedData.sort((a, b) => new Date(b.date) - new Date(a.date)); // Assuming `date` field exists
    }
    setClassifications(sortedData);
  };

  // Handle sort option change
  const handleSortChange = (e) => {
    const selectedOption = e.target.value;
    setSortOption(selectedOption);
  };

  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Classifications</p>
      <SearchBarHeader />

      {/* Sort Dropdown */}
      <div>
        <label
          htmlFor="sort"
          style={{ marginRight: "10px", fontWeight: "500", fontSize: "16px" }}
        >
          Sort by:
        </label>
        <select
          id="sort"
          value={sortOption}
          onChange={handleSortChange}
          className={styles.sort_dropdown}
        >
          <option value="newest">Newest</option>
          <option value="alphabetical">Alphabetical</option>
        </select>
      </div>

      <div className={styles.table_scroll}>
        {classifications.map((classification, index) => {
          return (
            <ClassificationsCard key={index} classification={classification} />
          );
        })}
        <div className={styles.buffer}></div>
      </div>
    </div>
  );
}

export default ClassificationsComponent;
