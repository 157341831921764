import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDhcbrgm1dDAm4mw4MUZNQbQX9In3zNI7I",
  authDomain: "nytelyfe-402203.firebaseapp.com",
  databaseURL: "https://nytelyfe-402203-default-rtdb.firebaseio.com",
  projectId: "nytelyfe-402203",
  storageBucket: "nytelyfe-402203.appspot.com",
  messagingSenderId: "1076346492065",
  appId: "1:1076346492065:web:a43f51a04945eae3b68ce0",
  measurementId: "G-LYQRGKG07K"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { app, auth, db, storage };
