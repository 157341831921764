import React from "react";
import styles from "./classifications.module.css";

function ClassificationsCard(props) {
  const classification = props.classification;

  return (
    <div className={styles.classifications_card}>
      {/* Updated to use the downloaded URL from Firebase */}
      <img
        className={styles.classifications_image}
        src={classification.downloadURL} // Use the Firebase download URL for the image
        alt="Classification"
      />
      <div className={styles.classifications_info}>
        <div className={styles.classifications_subinfo}>
          <p className={styles.classification_title}>{classification.name}</p>
          <p className={styles.classification_info_title}>
            Accuracy Score: {classification.accuracy}%
          </p>
          <p className={styles.classification_info_title}>
            Count: {classification.count}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ClassificationsCard;
