import React, { useEffect, useState } from "react";
import styles from "./notifications.module.css";
import notifications_data from "../../data/notifications_data";

const LineDivider = () => {
  return <div className={styles.lineDivider}></div>;
};

function NotificationsSidebar(props) {
  //
  /*
  useEffect(() => {
    loadNotifications
  }, [])
*/
  return (
    <div className={styles.notifications_sidebar}>
      <p className={styles.notifications_sidebar_text}>Notifications</p>
      <div className={styles.notifications_sidebar_content}>
        {notifications_data.map((notification, index) => (
          <div className={styles.notification_cell}>
            <img
              src={notification.image.url}
              className={styles.notif_image}
              alt={"notification"}
            />
            <div className={styles.notification_cell_text}>
              <div className={styles.notification_cell_top}>
                <p className={styles.notification_text}>{notification.text}</p>
              </div>
              <div className={styles.notification_cell_bottom}>
                <p className={styles.notification_time}>
                  {notification.timestamp}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default NotificationsSidebar;
