import { useState, useRef, useEffect } from "react";
import React from "react";
import styles from "./dashboard.module.css";

import DashboardRowOne from "./DashboardRow1";

import dashboard_data from "../../../data/dashboard_data";

export default function DashboardComponent() {
  return (
    <div className={styles.main_layout}>
      <DashboardRowOne
        dashboard_data={dashboard_data.campaigns_overview_stats}
        title="Campaigns Overview"
      />
    </div>
  );
}
