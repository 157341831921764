import React from "react";
import styles from "./livestream.module.css";
import live_stream_data from "../../../data/live_stream_data";
import LiveStreamTableCell from "./LiveStreamTableCell";

function LiveStreamTableContent() {
  return (
    <div className={styles.table_layout}>
      <div className={styles.table_results_controls}>
        <p className={styles.showing_results}>Showing 1 - 10 of 133 results</p>
        <div className={styles.page_result_controls}>
          <p>Results per page:</p>
          <div className={styles.page_result_filter}>
            <p className={styles.filter_text}>10</p>
            <img
              className={styles.down_icon}
              src="/DownArrowIcon.png"
              alt="search"
            ></img>
          </div>
        </div>
      </div>
      <div className={styles.table_info_section}>
        <LiveStreamTableCell />
      </div>
      <div className={styles.table_scroll}>
        {live_stream_data.map((dataPoint, index) => {
          return <LiveStreamTableCell key={index} dataPoint={dataPoint} />;
        })}
        <div className={styles.buffer}></div>
      </div>
    </div>
  );
}

export default LiveStreamTableContent;
