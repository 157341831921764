import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import ClassificationsComponent from "../../components/portal_components/ClassificationsComponents/ClassificationsComponent";

const ClassificationsPage = () => {
  return (
    <>
      <Sidebar
        childComponent={<ClassificationsComponent />}
        activeText="Classifications"
      />
    </>
  );
};

export default ClassificationsPage;
