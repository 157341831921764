const live_stream_data = [
  {
    id: "63042",
    created: "Nov 24, 08:24AM",
    classification: "Moth",
    order: "Lepidoptera",
    frequency: "4",
    device: "UW Madison Device 2",
  },
  {
    id: "67240",
    created: "Nov 24, 07:51AM",
    classification: "Spider",
    order: "Araneae",
    frequency: "4",
    device: "UW Madison Device 1",
  },
  {
    id: "64405",
    created: "Nov 25, 05:52AM",
    classification: "Ant",
    order: "Hymenoptera",
    frequency: "89",
    device: "UW Madison Device 2",
  },
  {
    id: "66329",
    created: "Nov 25, 12:38PM",
    classification: "Mosquito",
    order: "Diptera",
    frequency: "54",
    device: "UW Madison Device 1",
  },
  {
    id: "74855",
    created: "Nov 24, 10:39PM",
    classification: "Spider",
    order: "Araneae",
    frequency: "26",
    device: "UW Madison Device 1",
  },
  {
    id: "93421",
    created: "Nov 23, 11:58PM",
    classification: "Moth",
    order: "Lepidoptera",
    frequency: "98",
    device: "UW Madison Device 3",
  },
  {
    id: "82158",
    created: "Nov 25, 03:10PM",
    classification: "Butterfly",
    order: "Lepidoptera",
    frequency: "6",
    device: "UW Madison Device 2",
  },
  {
    id: "83127",
    created: "Nov 24, 08:41PM",
    classification: "Ant",
    order: "Hymenoptera",
    frequency: "2",
    device: "UW Madison Device 3",
  },
  {
    id: "46906",
    created: "Nov 25, 06:59AM",
    classification: "Ant",
    order: "Hymenoptera",
    frequency: "55",
    device: "UW Madison Device 3",
  },
  {
    id: "71500",
    created: "Nov 24, 04:00PM",
    classification: "Spider",
    order: "Araneae",
    frequency: "81",
    device: "UW Madison Device 3",
  },
  {
    id: "40332",
    created: "Nov 25, 01:12AM",
    classification: "Ant",
    order: "Hymenoptera",
    frequency: "70",
    device: "UW Madison Device 2",
  },
  {
    id: "13404",
    created: "Nov 25, 06:35AM",
    classification: "Mosquito",
    order: "Diptera",
    frequency: "26",
    device: "UW Madison Device 1",
  },
  {
    id: "12319",
    created: "Nov 25, 08:37PM",
    classification: "Butterfly",
    order: "Lepidoptera",
    frequency: "33",
    device: "UW Madison Device 1",
  },
  {
    id: "96991",
    created: "Nov 24, 04:26AM",
    classification: "Mosquito",
    order: "Diptera",
    frequency: "91",
    device: "UW Madison Device 3",
  },
  {
    id: "42085",
    created: "Nov 25, 12:47PM",
    classification: "Ant",
    order: "Hymenoptera",
    frequency: "50",
    device: "UW Madison Device 1",
  },
  {
    id: "18530",
    created: "Nov 25, 10:08PM",
    classification: "Moth",
    order: "Lepidoptera",
    frequency: "72",
    device: "UW Madison Device 3",
  },
  {
    id: "14765",
    created: "Nov 24, 11:00AM",
    classification: "Ant",
    order: "Hymenoptera",
    frequency: "84",
    device: "UW Madison Device 1",
  },
  {
    id: "81395",
    created: "Nov 24, 09:53AM",
    classification: "Spider",
    order: "Araneae",
    frequency: "36",
    device: "UW Madison Device 3",
  },
  {
    id: "61478",
    created: "Nov 25, 03:27AM",
    classification: "Spider",
    order: "Araneae",
    frequency: "30",
    device: "UW Madison Device 1",
  },
  {
    id: "40131",
    created: "Nov 24, 03:53AM",
    classification: "Ant",
    order: "Hymenoptera",
    frequency: "35",
    device: "UW Madison Device 2",
  },
  {
    id: "88907",
    created: "Nov 25, 07:18PM",
    classification: "Moth",
    order: "Lepidoptera",
    frequency: "42",
    device: "UW Madison Device 3",
  },
  {
    id: "82469",
    created: "Nov 25, 08:03PM",
    classification: "Moth",
    order: "Lepidoptera",
    frequency: "68",
    device: "UW Madison Device 1",
  },
  {
    id: "88437",
    created: "Nov 25, 04:16AM",
    classification: "Mosquito",
    order: "Diptera",
    frequency: "1",
    device: "UW Madison Device 2",
  },
  {
    id: "43639",
    created: "Nov 25, 03:04PM",
    classification: "Butterfly",
    order: "Lepidoptera",
    frequency: "100",
    device: "UW Madison Device 1",
  },
  {
    id: "64061",
    created: "Nov 25, 06:43AM",
    classification: "Ant",
    order: "Hymenoptera",
    frequency: "35",
    device: "UW Madison Device 3",
  },
  {
    id: "90811",
    created: "Nov 24, 04:35AM",
    classification: "Spider",
    order: "Araneae",
    frequency: "59",
    device: "UW Madison Device 3",
  },
  {
    id: "71385",
    created: "Nov 25, 12:43AM",
    classification: "Ant",
    order: "Hymenoptera",
    frequency: "15",
    device: "UW Madison Device 2",
  },
  {
    id: "81243",
    created: "Nov 25, 05:04PM",
    classification: "Moth",
    order: "Lepidoptera",
    frequency: "59",
    device: "UW Madison Device 3",
  },
  {
    id: "24233",
    created: "Nov 24, 08:29AM",
    classification: "Moth",
    order: "Lepidoptera",
    frequency: "17",
    device: "UW Madison Device 1",
  },
  {
    id: "58343",
    created: "Nov 25, 05:04PM",
    classification: "Mosquito",
    order: "Diptera",
    frequency: "62",
    device: "UW Madison Device 3",
  },
  {
    id: "38267",
    created: "Nov 25, 10:05AM",
    classification: "Ant",
    order: "Hymenoptera",
    frequency: "69",
    device: "UW Madison Device 3",
  },
  {
    id: "99706",
    created: "Nov 25, 04:39AM",
    classification: "Spider",
    order: "Araneae",
    frequency: "10",
    device: "UW Madison Device 1",
  },
  {
    id: "79173",
    created: "Nov 25, 11:12AM",
    classification: "Moth",
    order: "Lepidoptera",
    frequency: "56",
    device: "UW Madison Device 3",
  },
  {
    id: "22878",
    created: "Nov 25, 09:47AM",
    classification: "Mosquito",
    order: "Diptera",
    frequency: "20",
    device: "UW Madison Device 2",
  },
  {
    id: "81572",
    created: "Nov 24, 03:37AM",
    classification: "Mosquito",
    order: "Diptera",
    frequency: "49",
    device: "UW Madison Device 1",
  },
  {
    id: "14484",
    created: "Nov 25, 02:38AM",
    classification: "Moth",
    order: "Lepidoptera",
    frequency: "13",
    device: "UW Madison Device 2",
  },
  {
    id: "45191",
    created: "Nov 24, 11:25PM",
    classification: "Moth",
    order: "Lepidoptera",
    frequency: "81",
    device: "UW Madison Device 1",
  },
  {
    id: "89910",
    created: "Nov 25, 03:25PM",
    classification: "Ant",
    order: "Hymenoptera",
    frequency: "23",
    device: "UW Madison Device 2",
  },
  {
    id: "80563",
    created: "Nov 25, 04:10AM",
    classification: "Mosquito",
    order: "Diptera",
    frequency: "59",
    device: "UW Madison Device 3",
  },
  {
    id: "50145",
    created: "Nov 25, 07:28AM",
    classification: "Ant",
    order: "Hymenoptera",
    frequency: "75",
    device: "UW Madison Device 2",
  },
  {
    id: "29751",
    created: "Nov 24, 06:05AM",
    classification: "Moth",
    order: "Lepidoptera",
    frequency: "16",
    device: "UW Madison Device 2",
  },
  {
    id: "22679",
    created: "Nov 25, 07:21AM",
    classification: "Ant",
    order: "Hymenoptera",
    frequency: "69",
    device: "UW Madison Device 3",
  },
  {
    id: "13028",
    created: "Nov 24, 11:42AM",
    classification: "Ant",
    order: "Hymenoptera",
    frequency: "68",
    device: "UW Madison Device 1",
  },
  {
    id: "83792",
    created: "Nov 24, 04:37PM",
    classification: "Mosquito",
    order: "Diptera",
    frequency: "61",
    device: "UW Madison Device 1",
  },
  {
    id: "73396",
    created: "Nov 24, 07:37PM",
    classification: "Moth",
    order: "Lepidoptera",
    frequency: "24",
    device: "UW Madison Device 1",
  },
  {
    id: "10274",
    created: "Nov 24, 03:09AM",
    classification: "Butterfly",
    order: "Lepidoptera",
    frequency: "3",
    device: "UW Madison Device 1",
  },
  {
    id: "43046",
    created: "Nov 24, 12:15AM",
    classification: "Ant",
    order: "Hymenoptera",
    frequency: "43",
    device: "UW Madison Device 2",
  },
  {
    id: "53177",
    created: "Nov 25, 09:01PM",
    classification: "Ant",
    order: "Hymenoptera",
    frequency: "60",
    device: "UW Madison Device 2",
  },
  {
    id: "92847",
    created: "Nov 25, 11:50AM",
    classification: "Moth",
    order: "Lepidoptera",
    frequency: "91",
    device: "UW Madison Device 2",
  },
  {
    id: "12772",
    created: "Nov 25, 06:08PM",
    classification: "Butterfly",
    order: "Lepidoptera",
    frequency: "81",
    device: "UW Madison Device 1",
  },
];

export default live_stream_data;
