import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "../settings.module.css";
import { AuthContext } from "../../../../context/AuthContext";

export default function AccountSettings(props) {
  const { updateAccountProfilePicture } = useContext(AuthContext);

  const [selectedProfilePicture, setSelectedProfilePicture] = useState(null);
  const { profile } = props;
  const fileInputRef = useRef(null);

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedProfilePicture(URL.createObjectURL(file));
      // Call the updateAccountProfilePicture function here with userId and the file
      updateAccountProfilePicture(profile.uid, file);
    }
  };

  const handleUploadButtonClick = () => {
    // Trigger the file input element when the "Upload Profile Picture" button is clicked.
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className={styles.main_layout}>
      {profile && (
        <>
          <div className={styles.section}>
            <p className={styles.section_title}>Profile</p>
            <div className={styles.proflie_picture_section}>
              <div className={styles.profile_picture_bg}>
                <img
                  alt="logo"
                  src={
                    selectedProfilePicture ||
                    profile.profilePicture ||
                    "/DefaultAccountImage.png"
                  }
                  className={styles.profile_picture}
                ></img>
              </div>
              <div className={styles.update_profile_picture}>
                <p onClick={handleUploadButtonClick}>Upload Profile Picture</p>
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleProfilePictureChange}
                />
              </div>
            </div>
            <div />
            <div className={styles.profile_section}>
              <div className={styles.profile_picture_section_left}>
                <p className={styles.input_title}>First Name</p>
                <div className={styles.single_line_input}>
                  {profile.firstName}
                </div>
                <p className={styles.input_title}>Country</p>
                <div className={styles.single_line_input}></div>
                <p className={styles.input_title}>Company Name</p>
                <div className={styles.single_line_input}></div>
              </div>
              <div className={styles.profile_picture_section_right}>
                <p className={styles.input_title}>Last Name</p>
                <div className={styles.single_line_input}>
                  {profile.lastName}
                </div>
                <p className={styles.input_title}>Phone Number</p>
                <div className={styles.single_line_input}></div>
                <p className={styles.input_title}>Company Website</p>
                <div className={styles.single_line_input}></div>
              </div>
            </div>
          </div>
          <div className={styles.section}>
            <p className={styles.section_title}>Login Information</p>
            <div className={styles.profile_section}>
              <div className={styles.profile_picture_section_left}>
                <p className={styles.input_title}>Email Address</p>
                <div className={styles.single_line_input}>{profile.email}</div>
              </div>
              <div className={styles.profile_picture_section_right}>
                <p className={styles.input_title}>Password</p>
                <div className={styles.single_line_input}>********</div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
