import React from "react";
import { useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import App from "./App";

// import NotFound from "./pages/NotFound";

import SignInPage from "./pages/Authentication/SignInPage";
// import SignUpPage from "./pages/Authentication/SignUpPage";

// Remove HomePage import if not needed anymore
// import HomePage from "./pages/Landing/HomePage";
// import WaitlistPage from "./pages/Landing/WaitlistPage";

import DashboardPage from "./pages/Portal/DashboardPage";
import ClassificationsPage from "./pages/Portal/ClassificationsPage";
import LiveStreamPage from "./pages/Portal/LiveStreamPage";
import SettingsPage from "./pages/Portal/SettingsPage";

import { AuthContext } from "./context/AuthContext";

function PrivateRoute({ children }) {
  const { currentUser } = useContext(AuthContext);

  // Check for user data in localStorage
  const isUserAuthenticated = localStorage.getItem("authUser") || currentUser;

  return isUserAuthenticated ? children : <Navigate to="/sign-in" />;
}

function routes() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/* ROUTES FOR SIGNING IN */}
          <Route path="/" element={<Navigate to="/sign-in" />} /> {/* Redirect root to sign-in */}
          <Route path="/sign-in" element={<App childComp={<SignInPage />} />} />

          {/* ROUTES FOR PORTAL PAGES */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <App childComp={<DashboardPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/classifications"
            element={
              <PrivateRoute>
                <App childComp={<ClassificationsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/live-stream"
            element={
              <PrivateRoute>
                <App childComp={<LiveStreamPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <App childComp={<SettingsPage />} />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default routes;
